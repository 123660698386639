module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GIG Media","short_name":"GIG Media","start_url":"/","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"none","icons":[{"src":"/favicon.ico","sizes":"64x64","type":"image/ico"}],"legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-core-theme/gatsby-browser.js'),
      options: {"plugins":[],"siteName":"vikingecasinoer.dk","preconnectLinks":["https://www.google-analytics.com","https://www.googletagmanager.com","https://gm.innocraft.cloud","https://cdn.vikingecasinoer.dk"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GIG Media","short_name":"GIG Media","start_url":"/","display":"standalone","icon":"src/images/favicon.svg","crossOrigin":"use-credentials","icons":[{"src":"/favicon.ico","sizes":"64x64","type":"image/ico"}],"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"3866aa4e5fc2ff29b387ce46ed4ecaf9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
